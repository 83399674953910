import './App.css';
import RoutesComponents from './routes/RoutesComponents';

function App() {
  return (
    <div className="App">
      <RoutesComponents />
    </div>
  );
}

export default App;
