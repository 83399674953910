import React from "react";
import styles from "./Main.module.scss";
import Header from "../Header/Header";
import  spotify from "../../assets/Mainimg/brand_spotify.svg"
import slack from "../../assets/Mainimg/brand_slack.svg"
import netflix from "../../assets/Mainimg/brand_netflix.svg"
import lenovo from "../../assets/Mainimg/brand_lenovo.svg"
import Accounting from "../../assets/Mainimg/Main.svg"
 
function Main() {
  return (
    <>
      <div className={styles.main}>
        <Header />
        <div className="container">
          <div className={styles.mainContent}>
            <div className={styles.left}>
              <h1>Get The <b>Career</b> <br/> You Deserve </h1>
              <p>
                Etiam sollicitudin, ipsum eu pulvinar rutrum, tellus ipsum<br/>
                laoreet sapien, quis venenatis ante odio sit amet eros.
              </p>
              <div className={styles.search}>
                <input type="text" />
                <input type="text" />
                <button>
                  <img src="" alt="" />
                </button>
                <div className={styles.companie}>
                  <img src={spotify} alt="" />
                  <img src={slack} alt="" />
                  <img src={netflix} alt="" />
                  <img src={lenovo} alt="" />
                </div>
                <div className={styles.Job}>
                <div className={styles.jobs}>
                <h2>2m+</h2>
                <p>Jobs</p>
                  </div>
                  <hr/>
                  <div className={styles.jobs}>
                <h2>500k+</h2>
                <p>Successful Hiring</p>
                  </div>
                  <hr/>
                  <div className={styles.jobs}>
                <h2>250k+</h2>
                <p>Partners</p>
                  </div>
                  <hr/>
                  <div className={styles.jobs}>
                <h2>1,56k +</h2>
                <p>Employee</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.right}>
          <img src={Accounting} alt="" />

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Main;
