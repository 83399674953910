import React from 'react'
import { Route, Routes } from 'react-router-dom'
import HomePage from '../pages/HomePage'
import ListPage from '../pages/ListPage'
import DetailsPage from '../pages/DetailsPage'
import BlogDetailsPage from '../pages/BlogDetailsPage'
import CareerAboutPage from '../pages/CareerAboutPage'
import BlogPage from '../pages/BlogPage'
import ContactPage from '../pages/ContactPage'

function RoutesComponents() {
    return (
        <div>
            <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/listpage' element={<ListPage />} />
                <Route path='/detailspage' element={<DetailsPage />} />
                <Route path='/blogpage' element={<BlogPage />} />
                <Route path='/blogdetailspage' element={<BlogDetailsPage />} />
                <Route path='/careeraboutpage' element={<CareerAboutPage />} />
                <Route path='/contactpage' element={<ContactPage />} />
            </Routes>
        </div>
    )
}

export default RoutesComponents
