import React from "react";
import styles from "../Header/Header.module.scss";

import logo from "../../assets/Logo.svg";
import search from "../../assets/headersImg/search.svg";
import lang from "../../assets/headersImg/lang.svg";

function Header() {
  return (
    <div className={styles.header + " container"}>
      <div className={styles.left}>
        <img src={logo} alt="" />
        <a href="#">Home</a>
        <a href="#">Components</a>
        <a href="#">Pages</a>
        <a href="#">Documentation</a>
      </div>
      <div className={styles.right}>
        <button className={styles.headerbtnSearch}>
          <img src={search} alt="" />
        </button>
        <button className={styles.headerbtnLeng}>
          <img src={lang} alt="" />
        </button>
        <button className={styles.headerLogin}>Login</button>
        <button className={styles.headerJoin}>Join Us</button>
      </div>
    </div>
  );
}

export default Header;
